import React, { Component } from "react";
import "react-overlay-loader/styles.css";

import MainLayout from "../layouts/MainLayout";
import LoginPage from "../components/login";
class Login extends Component {
  render() {
    return (
      <MainLayout>
        <LoginPage />
      </MainLayout>
    );
  }
}

export default Login;

